import React, { useState } from "react";
import Header from "../../core/layout/Header";
import { PageLayoutTag } from "./PageLayoutTag"
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import styled from "styled-components";
import AnalyticsProvider from "../../contexts/AnalyticsProvider";
import Banner from "./Banner";
import moment from "moment";

type PageTemplateProps = {
  backgroundImage?: any,
  pageTitle: string
  pageThumbnail?: any,
  pageDescription?: string,
  lang?: string,
  pageCanonical?: string,
  footer?: boolean,
  center?: boolean,
  noTopPadding?: boolean,
}

export const PageTemplate: React.FC<PageTemplateProps> = ({
  lang = 'en',
  children,
  backgroundImage,
  pageTitle,
  pageThumbnail,
  pageDescription,
  pageCanonical,
  footer,
  center,
  noTopPadding
}) => {
  const meta = [
    {
      name: 'description',
      content: pageDescription,
    },
    {
      name: 'twitter:title',
      content: pageTitle,
    },
    {
      name: 'twitter:description',
      content: pageDescription,
    },
    {
      name: 'twitter:image',
      content: pageThumbnail,
    },
    {
      property: 'og:title',
      content: pageTitle,
    },
    {
      property: 'og:description',
      content: pageDescription,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: pageThumbnail,
    },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


  return (
    <AnalyticsProvider pageName={pageTitle}>
      <PageLayoutTag backgroundImage={backgroundImage} footer={footer}>
        <Helmet
          htmlAttributes={{
            lang
          }}
          link={[
            {
              rel: 'canonical',
              href: pageCanonical,
            },
          ]}
          meta={meta}
          title={pageTitle}
          titleTemplate={'%s'}
        />
        <Header />
        <div style={{ marginTop: '58px' }}>
          <Banner
            hide={false}
            endDate={moment("12/02/2024")}
            mobile={isMobile}
            texts={
              <span>
                In observance of the <b>Thanksgiving Holiday</b>, our customer support team will be unavailable on <b>Thursday, November 28</b>, and <b>Friday, November 29</b>. We’ll be back to assist you on <b>Monday, December 2</b>.
              </span>

            }
          />
        </div>
        <Body center={center} noTopPadding={noTopPadding}>
          {children}
        </Body>
        <Footer />
      </PageLayoutTag>
    </AnalyticsProvider>
  )
}

const Body: React.FC<{ center?: boolean, noTopPadding?: boolean }> = ({ center, noTopPadding, children }) => (
  <Container center={center} noTopPadding={noTopPadding}>
    {children}
  </Container>
)

const Container = styled.div<{ center?: boolean, noTopPadding?: boolean }>(({ center, noTopPadding }) => ({
  paddingTop: noTopPadding ? "60px" : "125px",
  paddingBottom: "38px",
  width: "100%",
  margin: "auto",
  display: "flex",
  ...(center ? {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } : {})
}))
