import { useEffect, useMemo,  } from "react";
import { ILoggedInUser } from "../models/ILoggedInUser";
import agents, { expireToken } from "../api/agent";
import environment from "../utils/environment";
import { SubscriptionTier } from "../enums/SubscriptionTier";
import Role from "../enums/Role";

export const useRedirectToNewWebApp = (loggedInUser: ILoggedInUser | null) => {
    const shouldStayAtLegacySite = useMemo(() => {
        if (loggedInUser) {
            //allow site level users to stay on the app
            if (Role.isSiteRole(loggedInUser?.roleID)) return true
            return loggedInUser?.subscriptionTier == SubscriptionTier['OldWebApp']
        }
        return true
    }, [loggedInUser])
    

    useEffect(() => {
        const redirectToNewWebApp = async () => {
            const response = await agents.Auth.createTokenFromUser();
            if (response.data) {
                expireToken();

                setTimeout(() => {
                    window.location.href = environment.newWebServerUrl + "/auth-redirect?uid=" + response.data;
                }, 0)
            }
        };

        if (!shouldStayAtLegacySite) {
            redirectToNewWebApp();
        } 
    }, [loggedInUser]);

    return shouldStayAtLegacySite;
};
