import moment from "moment"
import { ReactNode, useCallback, useEffect, useState } from "react"
import { H4Bold, PBold } from "../brand/typography"
import { Colors } from "../brand/themes"

const Banner = ({
    endDate,
    hide,
    mobile,
    texts
}: { endDate: moment.Moment, hide: boolean, mobile: boolean, texts: string | ReactNode }) => {
    const [open, setOpen] = useState(true)
    console.log({ open })

    // useEffect(() => {
    //     setOpen(
    //         !JSON.parse(localStorage.getItem("bannerClosed") || "false")
    //     )
    // }, [])

    const onClose = useCallback(() => {
        // localStorage.setItem("bannerClosed", "true")
        setOpen(false)
    }, [])

    if (!open || hide)
        return null

    if (moment().isSameOrAfter(endDate, 'day')) {
        return null;
    }

    const text = mobile ?
        "DaVinci Lock was impacted by the major IT outage occurring globally.  All core services are restored. Known issue: ESS automated messages are not being sent to tenants"
        :
        "DaVinci Lock was impacted by the major IT outage occurring globally, resulting in loss of services as of approximately 6pm Eastern, 7 / 18.  Currently all core services are restored.   There is a known issue that ESS automated messages are not being sent to tenants; our team is working to determine root cause at this time."

    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "auto 52px",
            marginTop: "4px",
            width: "100%",
            backgroundColor: Colors.red()
        }}>
            <PBold secondary style={{ padding: "16px" }}>
                {texts}
            </PBold>
            <H4Bold secondary onClick={onClose} style={{ paddingTop: "8px", cursor: "pointer" }}>x</H4Bold>
        </div>
    )
}

export default Banner
