import React, { useCallback, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import styled from "styled-components";
import NavMenu from "../../core/navigation-menu/NavMenu";
import Header from "../../core/layout/Header";
import { Colors, Styles } from "../brand/themes";
import { FlipBack, PageLayoutTag } from "./PageLayoutTag";
import Role from "../../enums/Role";
import moment from "moment";
import { H4Bold, PBold } from "../brand/typography";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import AnalyticsProvider from "../../contexts/AnalyticsProvider";
import ExceptionPage from "../error-fallbacks/ErrorPage"
import { ErrorBoundary } from "../../utils/errorHandling";
import { SubscriptionContext } from "../../contexts/SubscriptionProvider";
import Banner from "./Banner";

type LoggedInPageTemplateProps = {
  backgroundImage?: any,
  pageTitle: string
  pageThumbnail?: any,
  pageDescription?: string,
  lang?: string,
  pageCanonical?: string,
  footer?: boolean,
  centerChildren?: boolean,
  extraPadding?: boolean,
}

export const LoggedInPageTemplate: React.FC<LoggedInPageTemplateProps> = ({
  lang = 'en',
  children,
  backgroundImage,
  pageTitle,
  pageThumbnail,
  pageDescription,
  pageCanonical,
  footer,
  centerChildren,
  extraPadding,
}) => {
  const meta = [
    {
      name: 'description',
      content: pageDescription,
    },
    {
      name: 'twitter:title',
      content: pageTitle,
    },
    {
      name: 'twitter:description',
      content: pageDescription,
    },
    {
      name: 'twitter:image',
      content: pageThumbnail,
    },
    {
      property: 'og:title',
      content: pageTitle,
    },
    {
      property: 'og:description',
      content: pageDescription,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: pageThumbnail,
    },
  ];

  return (
    <AnalyticsProvider pageName={pageTitle}>
      <PageLayoutTag flip backgroundImage={backgroundImage} footer={footer}>
        <Helmet
          htmlAttributes={{
            lang
          }}
          link={[
            {
              rel: 'canonical',
              href: pageCanonical,
            },
          ]}
          meta={meta}
          title={pageTitle}
          titleTemplate={'%s'}
        />
        <FlipBack>
          <Header ></Header>
          <Body centerChildren={centerChildren} extraPadding={extraPadding} >
            {children}
          </Body>
          <Footer />
        </FlipBack>
      </PageLayoutTag>
    </AnalyticsProvider>
  )
}

const Body: React.FC<{ centerChildren?: boolean, extraPadding?: boolean }> = ({ centerChildren, extraPadding, children }) => {
  const { loggedInUser, loading } = useLoggedInUser();
  const { renderDialog } = useContext(SubscriptionContext)

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  if (loading || !loggedInUser?.roleID) return null
  const noNav = loggedInUser?.roleID === Role["Call Center Agent"] || loggedInUser.roleID === Role.Vendor;

  const renderNavMenu = () => {
    if (noNav) return null

    return (
      <div style={{ width: "275px" }}>
        <NavMenuWrapper>
          <NavMenu />
        </NavMenuWrapper >
      </div>
    )
  }

  return (
    <Container extraPadding={extraPadding} centerChildren={centerChildren}>
      <PageGrid>
        {renderNavMenu()}
        <div style={noNav ? { gridColumn: "1/-1", margin: "auto", width: "100%" } : {}}>
          <Banner
            hide={false}
            endDate={moment("07/22/2024")}
            mobile={isMobile}
          />
          <ErrorBoundary
            fallback={<ExceptionPage />}>
            {renderDialog && renderDialog()}
            {children}
          </ErrorBoundary>
        </div>
      </PageGrid>
    </Container>
  )
}



const PageGrid = styled.div({
  display: "grid",
  gridTemplateColumns: "275px 1fr",
  alignItems: "start",
  width: "100%",
  margin: "auto",
  "@media(max-width:865px)": {
    display: "block",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  }
})

const NavMenuWrapper = styled.div({
  "::-webkit-scrollbar": {
    height: "0px",
    width: "0px",
  },
  overflowY: "scroll",
  backgroundColor: Colors.white(),
  width: "275px",
  position: "absolute",
  top: "58px",
  left: "0px",
  maxHeight: "calc(100vh - 58px)",
  height: "calc(100vh - 92px)",
  ...Styles.shadow(),
  "@media(max-width:865px)": {
    display: "none",
    boxShadow: "none",
  },
})

const Container = styled.div<{ centerChildren?: boolean, extraPadding?: boolean }>(({ centerChildren, extraPadding }) => ({
  padding: `${extraPadding ? "125px" : "56px"} 16px 70px 16px`,
  width: "calc(100% - 32px)",
  margin: "auto",
  display: "flex",
  ...(centerChildren ? {
    marginLeft: "-100px",
    "@media(max-width:865px)": {
      marginLeft: "0"
    }
  } : {}),
  '@media(max-width: 865px)': {
    padding: `${extraPadding ? "125px" : "72px"} 16px 70px 16px`,
  }
}))
